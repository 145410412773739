import React, { useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { db, storage } from '../../firebase'; // Ensure storage is correctly initialized
import { collection, addDoc, doc, getDocs, query, where, Timestamp, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';

const FormContainer = styled.div`
  margin: auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.91);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(100.4px);
    -webkit-backdrop-filter: blur(6.4px);
    border: 1px solid rgba(255, 255, 255, 0.19);
    border-radius: 10px;
    /* background: rgba(255, 255, 255, 0.85); */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const validationSchema = Yup.object({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
  education: Yup.string().required('Education level is required'),
  totalExperience: Yup.number().min(0, 'Years of experience cannot be negative').required('Total Years of Work Experience is required'),
  currentCTC: Yup.number().min(0, 'CTC must be a positive value').required('Current CTC is required'),
  expectedCTC: Yup.number().min(0, 'Expected CTC must be a positive value').required('Expected CTC is required'),
  resume: Yup.mixed().required('Resume is required'),
});

const JobApplicationForm = ({ jobTitle }) => {
  const [resumeFile, setResumeFile] = useState(null);

  const initialValues = {
    fullName: '',
    email: '',
    phone: '',
    education: '',
    totalExperience: '',
    currentCTC: '',
    expectedCTC: '',
    resume: null,
    AppliedDate: Timestamp.now(),
  };

  const fetchEmailKeys = async () => {
    const docRef = doc(db, "emailConfig", "emailKeys");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const { service_id, template_id, public_key } = docSnap.data();
      return { service_id, template_id, public_key };
    } else {
      throw new Error("No email configuration found!");
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      if (!resumeFile) {
        toast.error('Please upload a resume!', { position: 'top-center' });
        setSubmitting(false);
        return;
      }
  
      // Check for duplicate applications
      const jobApplicationsRef = collection(db, 'jobApplications');
      const querySnapshot = await getDocs(
        query(
          jobApplicationsRef,
          where('email', '==', values.email),
          where('phone', '==', values.phone),
          where('fullName', '==', values.fullName),
          where('AppliedRole', '==', jobTitle)
        )
      );
  
      if (!querySnapshot.empty) {
        toast.error('You have already applied for this position.', { position: 'top-center', autoClose: 3000 });
        setSubmitting(false);
        return;
      }
  
      // Upload resume to Firebase Storage
      const resumeRef = ref(storage, `resumes/${values.email}_${Date.now()}`);
      await uploadBytes(resumeRef, resumeFile);
      const resumeURL = await getDownloadURL(resumeRef);
  
      // Store application in Firestore
      const structuredData = {
        fullName: values.fullName,
        email: values.email,
        phone: values.phone,
        education: values.education,
        totalExperience: values.totalExperience,
        currentCTC: values.currentCTC,
        expectedCTC: values.expectedCTC,
        AppliedRole: jobTitle,
        AppliedDate: Timestamp.now(),
        resumeURL,
      };
  
      await addDoc(collection(db, 'jobApplications'), structuredData);
  
      // Send email via EmailJS
      const { service_id, template_id, public_key } = await fetchEmailKeys();
      const templateParams = { ...structuredData };
      await emailjs.send(service_id, template_id, templateParams, public_key);
  
      toast.success('Application submitted successfully!', { position: 'top-right', autoClose: 5000 });
  
      // Reset form and file input
      resetForm(); // Resets Formik fields
      setResumeFile(null); // Clears the file state
  
      // Explicitly reset the file input field
      document.querySelector('input[type="file"]').value = '';
  
    } catch (error) {
      console.error('Error submitting application:', error);
      toast.error('Failed to submit application. Please try again.', { position: 'top-center', autoClose: 3000 });
    } finally {
      setSubmitting(false);
    }
  };
  

  return (
    <FormContainer>
      <ToastContainer />
      <h3 style={{ color: '#ef5226' }} className="text-center mt-3 mb-3">Job Application Form</h3>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
              <Form.Group controlId="fullName" className="mt-3">
                  <Form.Label>Full Name</Form.Label>
                  <Field
                    type="text"
                    name="fullName"
                    placeholder="Enter full name"
                    className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                  />
                  <ErrorMessage name="fullName" component="div" className="text-danger" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                  />
                  <ErrorMessage name="email" component="div" className="text-danger" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Form.Group controlId="phone" className="mt-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Field
                    type="tel"
                    name="phone"
                    placeholder="Enter phone number"
                    className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                  />
                  <ErrorMessage name="phone" component="div" className="text-danger" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="education" className="mt-3">
                  <Form.Label>Education Level</Form.Label>
                  <Field
                    as="select"
                    name="education"
                    className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select</option>
                    <option value="High School">High School</option>
                    <option value="Bachelor's Degree">Bachelor's Degree</option>
                    <option value="Master's Degree">Master's Degree</option>
                    <option value="PhD">PhD</option>
                  </Field>
                  <ErrorMessage name="education" component="div" className="text-danger" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
              <Form.Group controlId="totalExperience" className="mt-3">
              <Form.Label>Total Years of Work Experience</Form.Label>
              <Field
                type="number"
                name="totalExperience"
                placeholder="Enter total years of experience"
                className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                />
              <ErrorMessage name="totalExperience" component="div" className="text-danger" />
            </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="currentCTC" className="mt-3">
                  <Form.Label>Current CTC</Form.Label>
                  <Field
  type="number"
  name="currentCTC"
  placeholder="Enter current CTC"
  className={`form-control ${touched.currentCTC && errors.currentCTC ? 'is-invalid' : ''}`}
/>
<ErrorMessage name="currentCTC" component="div" className="text-danger" />

                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="expectedCTC" className="mt-3">
                  <Form.Label>Expected CTC</Form.Label>
                  <Field
  type="number"
  name="expectedCTC"
  placeholder="Enter expected CTC"
  className={`form-control ${touched.expectedCTC && errors.expectedCTC ? 'is-invalid' : ''}`}
/>
<ErrorMessage name="expectedCTC" component="div" className="text-danger" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mt-3">
              <Form.Label>Resume</Form.Label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setResumeFile(e.target.files[0]);
                  setFieldValue('resume', e.target.files[0]);
                }}
              />
              <ErrorMessage name="resume" component="div" className="text-danger" />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="primary"
                className="mt-5 mb-5"
                style={{ backgroundColor: 'rgb(239, 82, 38)', color: 'white', border: 'none' }}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Application'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

export default JobApplicationForm;